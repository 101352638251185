'use strict'

window.jQuery = window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('./components/trackingEvents'));
    processInclude(require('base_bofrost/countrySelector'));
    processInclude(require('./matomoEventListener'));
    processInclude(require('./customDropdown'));
    processInclude(require('base_bofrost/main'));
});
