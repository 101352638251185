window.jQuery = window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('./login/login'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/productcarousel'));
    processInclude(require('./cart/cart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/header'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/sideMenu'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/zipModal'));
    processInclude(require('./wishlistButton'));
    processInclude(require('./modalLogin'));
    processInclude(require('./_partnerModal'));
    $(function () {
        const $tooltip = $('[data-toggle="tooltip"]');
        if($tooltip.length > 0) {
            $tooltip.tooltip();
        }
    });
});

require('lazysizes');
require('lazysizes/plugins/bgset/ls.bgset');
require('./thirdParty/bootstrap');
require('./thirdParty/slick');
require('./components/spinner');
require('./components/toast');
