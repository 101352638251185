'use strict';

const $userLogged = $('.js-user-logged');

function getUserID() {
    return $userLogged.length ? $userLogged.data('user') : null;
}

function mtmProductImpression({ event, category, items, totalPrice, currency, coupons, payment_type, shipping, transaction_id, tax}) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        event,
        ecommerce: {
            user_id,
            items
        }
    }

    if (!user_id) {
        delete obj.ecommerce.user_id;
    }

    switch (event) {
        case 'change_cart':
        case 'remove_from_cart':
        case 'add_to_cart':
        case 'begin_checkout':
        case 'view_cart':
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.currency = currency;
            break;
        case 'add_payment_info':
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.currency = currency;
            obj.ecommerce.payment_type = payment_type;
            break;
        case 'purchase':
            obj.page_type = 'checkout';
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.tax = tax;
            obj.ecommerce.shipping = shipping;
            obj.ecommerce.currency = currency;
            obj.ecommerce.transaction_id = transaction_id;
            break;
        case 'view_item':
            obj.page_type = 'single_item';
            break;
        case 'view_category':
            delete obj.ecommerce.items;
            obj.page_type = 'category';
            obj.ecommerce.list_category = category;
            break;
    }

    if (coupons) {
        obj.ecommerce.coupons = coupons;
    }

    _mtm.push(obj);
}

function mtmUserImpression({ event, method, customer_type, user_id }) {
    if(typeof (_mtm) === "undefined"){return};

    const obj = {
        user_id,
        event,
        method,
        customer_type
    }

    if (!customer_type) {
        delete obj.customer_type;
    }

    if (!user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}


function mtmSearchResult({ event, search_phrase, search_result_count }) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        user_id,
        event,
        page_type: 'search_result',
        search_phrase,
        search_result_count
    }

    if (!obj.user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}

function mtmVerifyCap({ cap }) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        event: 'check_coverage',
        user_id,
        cap
    }

    if (!obj.user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}

function mtmNewsletterSubscription() {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        event: "newsletter_subscription",
        user_id
    }

    if (!user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}

function mtmCouponApplied({ coupon }) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        event: "coupon_applied",
        user_id,
        ecommerce: {
            coupon
        }
    }

    if (!user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}

function mtmContactFormSubmit({ form_purpose }) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    const obj = {
        user_id,
        event: "form_submit",
        form_purpose
    }

    if (!user_id) {
        delete obj.user_id;
    }

    _mtm.push(obj);
}

function mtmProductFilters({ selected_items }) {
    if(typeof (_mtm) === "undefined"){return};

    const user_id = getUserID();

    if (selected_items.length) {
        selected_items.forEach((item) => {
            const obj = {
                user_id,
                event: "select_allergens",
                selected_items: {
                    name: item.name
                }
            }

            if (!user_id) {
                delete obj.user_id;
            }

            _mtm.push(obj);
        });
    }

}

function mtmDownloadCatalog({ file_name }) {
    if(typeof (_mtm) === undefined){return};

    const user_id = getUserID();

    const obj = {
        user_id,
        event: "file_download",
        file_name
    }

    if (!user_id) {
        delete obj.user_id;
    }

    // Scaricare catalogo
    _mtm.push(obj);
}

function mtmPageView({ event, page_type }) {
    if(typeof (_mtm) === "undefined"){return};

    const obj = {
        event,
        page_type
    }

    // Scaricare catalogo
    _mtm.push(obj);
}

function mtmGeneralEvent({ event }) {
    if(typeof (_mtm) === "undefined"){return};

    const obj = {
        event,
    }

    // Scaricare catalogo
    _mtm.push(obj);
}

module.exports = {
    mtmProductImpression: mtmProductImpression,
    mtmUserImpression: mtmUserImpression,
    mtmVerifyCap: mtmVerifyCap,
    mtmContactFormSubmit: mtmContactFormSubmit,
    mtmNewsletterSubscription: mtmNewsletterSubscription,
    mtmProductFilters: mtmProductFilters,
    mtmCouponApplied: mtmCouponApplied,
    mtmDownloadCatalog: mtmDownloadCatalog,
    mtmSearchResult: mtmSearchResult,
    mtmPageView: mtmPageView,
    mtmGeneralEvent: mtmGeneralEvent
}
